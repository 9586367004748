/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createGlobalConfigurations = /* GraphQL */ `mutation CreateGlobalConfigurations(
  $input: CreateGlobalConfigurationsInput!
  $condition: ModelGlobalConfigurationsConditionInput
) {
  createGlobalConfigurations(input: $input, condition: $condition) {
    id
    latestAppVersion
    miniApps {
      id
      type
      timestamp
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGlobalConfigurationsMutationVariables,
  APITypes.CreateGlobalConfigurationsMutation
>;
export const updateGlobalConfigurations = /* GraphQL */ `mutation UpdateGlobalConfigurations(
  $input: UpdateGlobalConfigurationsInput!
  $condition: ModelGlobalConfigurationsConditionInput
) {
  updateGlobalConfigurations(input: $input, condition: $condition) {
    id
    latestAppVersion
    miniApps {
      id
      type
      timestamp
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGlobalConfigurationsMutationVariables,
  APITypes.UpdateGlobalConfigurationsMutation
>;
export const deleteGlobalConfigurations = /* GraphQL */ `mutation DeleteGlobalConfigurations(
  $input: DeleteGlobalConfigurationsInput!
  $condition: ModelGlobalConfigurationsConditionInput
) {
  deleteGlobalConfigurations(input: $input, condition: $condition) {
    id
    latestAppVersion
    miniApps {
      id
      type
      timestamp
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGlobalConfigurationsMutationVariables,
  APITypes.DeleteGlobalConfigurationsMutation
>;
export const createRobotAction = /* GraphQL */ `mutation CreateRobotAction(
  $input: CreateRobotActionInput!
  $condition: ModelRobotActionConditionInput
) {
  createRobotAction(input: $input, condition: $condition) {
    organizationID
    actionID
    actionName
    actionNameEmbedding
    embeddingModel
    textToSayEn
    textToSayZh
    actionBlocklyProjectId
    actionBlocklyProjectVersion
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRobotActionMutationVariables,
  APITypes.CreateRobotActionMutation
>;
export const updateRobotAction = /* GraphQL */ `mutation UpdateRobotAction(
  $input: UpdateRobotActionInput!
  $condition: ModelRobotActionConditionInput
) {
  updateRobotAction(input: $input, condition: $condition) {
    organizationID
    actionID
    actionName
    actionNameEmbedding
    embeddingModel
    textToSayEn
    textToSayZh
    actionBlocklyProjectId
    actionBlocklyProjectVersion
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRobotActionMutationVariables,
  APITypes.UpdateRobotActionMutation
>;
export const deleteRobotAction = /* GraphQL */ `mutation DeleteRobotAction(
  $input: DeleteRobotActionInput!
  $condition: ModelRobotActionConditionInput
) {
  deleteRobotAction(input: $input, condition: $condition) {
    organizationID
    actionID
    actionName
    actionNameEmbedding
    embeddingModel
    textToSayEn
    textToSayZh
    actionBlocklyProjectId
    actionBlocklyProjectVersion
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRobotActionMutationVariables,
  APITypes.DeleteRobotActionMutation
>;
export const createAudioRecording = /* GraphQL */ `mutation CreateAudioRecording(
  $input: CreateAudioRecordingInput!
  $condition: ModelAudioRecordingConditionInput
) {
  createAudioRecording(input: $input, condition: $condition) {
    s3bucketKey
    whisperResult
    googleSpeechToText
    awsTranscribeResult
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAudioRecordingMutationVariables,
  APITypes.CreateAudioRecordingMutation
>;
export const updateAudioRecording = /* GraphQL */ `mutation UpdateAudioRecording(
  $input: UpdateAudioRecordingInput!
  $condition: ModelAudioRecordingConditionInput
) {
  updateAudioRecording(input: $input, condition: $condition) {
    s3bucketKey
    whisperResult
    googleSpeechToText
    awsTranscribeResult
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAudioRecordingMutationVariables,
  APITypes.UpdateAudioRecordingMutation
>;
export const deleteAudioRecording = /* GraphQL */ `mutation DeleteAudioRecording(
  $input: DeleteAudioRecordingInput!
  $condition: ModelAudioRecordingConditionInput
) {
  deleteAudioRecording(input: $input, condition: $condition) {
    s3bucketKey
    whisperResult
    googleSpeechToText
    awsTranscribeResult
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAudioRecordingMutationVariables,
  APITypes.DeleteAudioRecordingMutation
>;
export const createConversationHistory = /* GraphQL */ `mutation CreateConversationHistory(
  $input: CreateConversationHistoryInput!
  $condition: ModelConversationHistoryConditionInput
) {
  createConversationHistory(input: $input, condition: $condition) {
    deviceID
    startDateTime
    messages {
      role
      content
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateConversationHistoryMutationVariables,
  APITypes.CreateConversationHistoryMutation
>;
export const updateConversationHistory = /* GraphQL */ `mutation UpdateConversationHistory(
  $input: UpdateConversationHistoryInput!
  $condition: ModelConversationHistoryConditionInput
) {
  updateConversationHistory(input: $input, condition: $condition) {
    deviceID
    startDateTime
    messages {
      role
      content
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateConversationHistoryMutationVariables,
  APITypes.UpdateConversationHistoryMutation
>;
export const deleteConversationHistory = /* GraphQL */ `mutation DeleteConversationHistory(
  $input: DeleteConversationHistoryInput!
  $condition: ModelConversationHistoryConditionInput
) {
  deleteConversationHistory(input: $input, condition: $condition) {
    deviceID
    startDateTime
    messages {
      role
      content
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteConversationHistoryMutationVariables,
  APITypes.DeleteConversationHistoryMutation
>;
export const createQuestionAndAnswerRecordsV2 = /* GraphQL */ `mutation CreateQuestionAndAnswerRecordsV2(
  $input: CreateQuestionAndAnswerRecordsV2Input!
  $condition: ModelQuestionAndAnswerRecordsV2ConditionInput
) {
  createQuestionAndAnswerRecordsV2(input: $input, condition: $condition) {
    deviceID
    dateTime
    source
    question
    answer
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateQuestionAndAnswerRecordsV2MutationVariables,
  APITypes.CreateQuestionAndAnswerRecordsV2Mutation
>;
export const updateQuestionAndAnswerRecordsV2 = /* GraphQL */ `mutation UpdateQuestionAndAnswerRecordsV2(
  $input: UpdateQuestionAndAnswerRecordsV2Input!
  $condition: ModelQuestionAndAnswerRecordsV2ConditionInput
) {
  updateQuestionAndAnswerRecordsV2(input: $input, condition: $condition) {
    deviceID
    dateTime
    source
    question
    answer
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateQuestionAndAnswerRecordsV2MutationVariables,
  APITypes.UpdateQuestionAndAnswerRecordsV2Mutation
>;
export const deleteQuestionAndAnswerRecordsV2 = /* GraphQL */ `mutation DeleteQuestionAndAnswerRecordsV2(
  $input: DeleteQuestionAndAnswerRecordsV2Input!
  $condition: ModelQuestionAndAnswerRecordsV2ConditionInput
) {
  deleteQuestionAndAnswerRecordsV2(input: $input, condition: $condition) {
    deviceID
    dateTime
    source
    question
    answer
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteQuestionAndAnswerRecordsV2MutationVariables,
  APITypes.DeleteQuestionAndAnswerRecordsV2Mutation
>;
export const createAppLogging = /* GraphQL */ `mutation CreateAppLogging(
  $input: CreateAppLoggingInput!
  $condition: ModelAppLoggingConditionInput
) {
  createAppLogging(input: $input, condition: $condition) {
    userID
    dateTime
    namespace
    message
    stacktrace
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAppLoggingMutationVariables,
  APITypes.CreateAppLoggingMutation
>;
export const updateAppLogging = /* GraphQL */ `mutation UpdateAppLogging(
  $input: UpdateAppLoggingInput!
  $condition: ModelAppLoggingConditionInput
) {
  updateAppLogging(input: $input, condition: $condition) {
    userID
    dateTime
    namespace
    message
    stacktrace
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAppLoggingMutationVariables,
  APITypes.UpdateAppLoggingMutation
>;
export const deleteAppLogging = /* GraphQL */ `mutation DeleteAppLogging(
  $input: DeleteAppLoggingInput!
  $condition: ModelAppLoggingConditionInput
) {
  deleteAppLogging(input: $input, condition: $condition) {
    userID
    dateTime
    namespace
    message
    stacktrace
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAppLoggingMutationVariables,
  APITypes.DeleteAppLoggingMutation
>;
export const createOrganizationData = /* GraphQL */ `mutation CreateOrganizationData(
  $input: CreateOrganizationDataInput!
  $condition: ModelOrganizationDataConditionInput
) {
  createOrganizationData(input: $input, condition: $condition) {
    organizationID
    name
    compreFaceFaceRecognitionApiKey
    usePaidSpeechToText
    usePaidTextToSpeech
    miniApps {
      id
      type
      timestamp
      __typename
    }
    createdAt
    updatedAt
    isSchoolManagementEnabled
    isKebbiForBusinessEnabled
    allowedOrgRoles
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganizationDataMutationVariables,
  APITypes.CreateOrganizationDataMutation
>;
export const updateOrganizationData = /* GraphQL */ `mutation UpdateOrganizationData(
  $input: UpdateOrganizationDataInput!
  $condition: ModelOrganizationDataConditionInput
) {
  updateOrganizationData(input: $input, condition: $condition) {
    organizationID
    name
    compreFaceFaceRecognitionApiKey
    usePaidSpeechToText
    usePaidTextToSpeech
    miniApps {
      id
      type
      timestamp
      __typename
    }
    createdAt
    updatedAt
    isSchoolManagementEnabled
    isKebbiForBusinessEnabled
    allowedOrgRoles
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganizationDataMutationVariables,
  APITypes.UpdateOrganizationDataMutation
>;
export const deleteOrganizationData = /* GraphQL */ `mutation DeleteOrganizationData(
  $input: DeleteOrganizationDataInput!
  $condition: ModelOrganizationDataConditionInput
) {
  deleteOrganizationData(input: $input, condition: $condition) {
    organizationID
    name
    compreFaceFaceRecognitionApiKey
    usePaidSpeechToText
    usePaidTextToSpeech
    miniApps {
      id
      type
      timestamp
      __typename
    }
    createdAt
    updatedAt
    isSchoolManagementEnabled
    isKebbiForBusinessEnabled
    allowedOrgRoles
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrganizationDataMutationVariables,
  APITypes.DeleteOrganizationDataMutation
>;
export const createFaceRecognitionMomentsForImprovementsLog = /* GraphQL */ `mutation CreateFaceRecognitionMomentsForImprovementsLog(
  $input: CreateFaceRecognitionMomentsForImprovementsLogInput!
  $condition: ModelFaceRecognitionMomentsForImprovementsLogConditionInput
) {
  createFaceRecognitionMomentsForImprovementsLog(
    input: $input
    condition: $condition
  ) {
    id
    kebbiDeviceId
    organizationId
    recognitionHistory {
      results {
        similarity
        subject
        __typename
      }
      __typename
    }
    outcome
    logReason
    recognizedUser
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFaceRecognitionMomentsForImprovementsLogMutationVariables,
  APITypes.CreateFaceRecognitionMomentsForImprovementsLogMutation
>;
export const updateFaceRecognitionMomentsForImprovementsLog = /* GraphQL */ `mutation UpdateFaceRecognitionMomentsForImprovementsLog(
  $input: UpdateFaceRecognitionMomentsForImprovementsLogInput!
  $condition: ModelFaceRecognitionMomentsForImprovementsLogConditionInput
) {
  updateFaceRecognitionMomentsForImprovementsLog(
    input: $input
    condition: $condition
  ) {
    id
    kebbiDeviceId
    organizationId
    recognitionHistory {
      results {
        similarity
        subject
        __typename
      }
      __typename
    }
    outcome
    logReason
    recognizedUser
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFaceRecognitionMomentsForImprovementsLogMutationVariables,
  APITypes.UpdateFaceRecognitionMomentsForImprovementsLogMutation
>;
export const deleteFaceRecognitionMomentsForImprovementsLog = /* GraphQL */ `mutation DeleteFaceRecognitionMomentsForImprovementsLog(
  $input: DeleteFaceRecognitionMomentsForImprovementsLogInput!
  $condition: ModelFaceRecognitionMomentsForImprovementsLogConditionInput
) {
  deleteFaceRecognitionMomentsForImprovementsLog(
    input: $input
    condition: $condition
  ) {
    id
    kebbiDeviceId
    organizationId
    recognitionHistory {
      results {
        similarity
        subject
        __typename
      }
      __typename
    }
    outcome
    logReason
    recognizedUser
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFaceRecognitionMomentsForImprovementsLogMutationVariables,
  APITypes.DeleteFaceRecognitionMomentsForImprovementsLogMutation
>;
export const createKebbiDevice = /* GraphQL */ `mutation CreateKebbiDevice(
  $input: CreateKebbiDeviceInput!
  $condition: ModelKebbiDeviceConditionInput
) {
  createKebbiDevice(input: $input, condition: $condition) {
    id
    organizationID
    cognitoIdentityID
    cognitoUserID
    password
    deviceServiceStatus {
      serviceName
      expirationDate
      __typename
    }
    registeredFaces {
      file {
        lastUpdatedTimestamp
        name
        category
        identityId
        __typename
      }
      fullName
      preferredName
      __typename
    }
    version
    createdAt
    updatedAt
    isTestDevice
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateKebbiDeviceMutationVariables,
  APITypes.CreateKebbiDeviceMutation
>;
export const updateKebbiDevice = /* GraphQL */ `mutation UpdateKebbiDevice(
  $input: UpdateKebbiDeviceInput!
  $condition: ModelKebbiDeviceConditionInput
) {
  updateKebbiDevice(input: $input, condition: $condition) {
    id
    organizationID
    cognitoIdentityID
    cognitoUserID
    password
    deviceServiceStatus {
      serviceName
      expirationDate
      __typename
    }
    registeredFaces {
      file {
        lastUpdatedTimestamp
        name
        category
        identityId
        __typename
      }
      fullName
      preferredName
      __typename
    }
    version
    createdAt
    updatedAt
    isTestDevice
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateKebbiDeviceMutationVariables,
  APITypes.UpdateKebbiDeviceMutation
>;
export const deleteKebbiDevice = /* GraphQL */ `mutation DeleteKebbiDevice(
  $input: DeleteKebbiDeviceInput!
  $condition: ModelKebbiDeviceConditionInput
) {
  deleteKebbiDevice(input: $input, condition: $condition) {
    id
    organizationID
    cognitoIdentityID
    cognitoUserID
    password
    deviceServiceStatus {
      serviceName
      expirationDate
      __typename
    }
    registeredFaces {
      file {
        lastUpdatedTimestamp
        name
        category
        identityId
        __typename
      }
      fullName
      preferredName
      __typename
    }
    version
    createdAt
    updatedAt
    isTestDevice
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteKebbiDeviceMutationVariables,
  APITypes.DeleteKebbiDeviceMutation
>;
export const createOrganizationUser = /* GraphQL */ `mutation CreateOrganizationUser(
  $input: CreateOrganizationUserInput!
  $condition: ModelOrganizationUserConditionInput
) {
  createOrganizationUser(input: $input, condition: $condition) {
    organizationID
    username
    status
    role
    createdAt
    updatedAt
    isShadowAdmin
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganizationUserMutationVariables,
  APITypes.CreateOrganizationUserMutation
>;
export const updateOrganizationUser = /* GraphQL */ `mutation UpdateOrganizationUser(
  $input: UpdateOrganizationUserInput!
  $condition: ModelOrganizationUserConditionInput
) {
  updateOrganizationUser(input: $input, condition: $condition) {
    organizationID
    username
    status
    role
    createdAt
    updatedAt
    isShadowAdmin
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganizationUserMutationVariables,
  APITypes.UpdateOrganizationUserMutation
>;
export const deleteOrganizationUser = /* GraphQL */ `mutation DeleteOrganizationUser(
  $input: DeleteOrganizationUserInput!
  $condition: ModelOrganizationUserConditionInput
) {
  deleteOrganizationUser(input: $input, condition: $condition) {
    organizationID
    username
    status
    role
    createdAt
    updatedAt
    isShadowAdmin
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrganizationUserMutationVariables,
  APITypes.DeleteOrganizationUserMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    userName
    cognitoIdentityID
    password
    organizations
    profileImage {
      s3Key
      versionNumber
      documentType
      __typename
    }
    parentName
    legalFullName
    preferredName
    country
    email
    phoneNumber
    dateOfBirth
    about
    notes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    userName
    cognitoIdentityID
    password
    organizations
    profileImage {
      s3Key
      versionNumber
      documentType
      __typename
    }
    parentName
    legalFullName
    preferredName
    country
    email
    phoneNumber
    dateOfBirth
    about
    notes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    userName
    cognitoIdentityID
    password
    organizations
    profileImage {
      s3Key
      versionNumber
      documentType
      __typename
    }
    parentName
    legalFullName
    preferredName
    country
    email
    phoneNumber
    dateOfBirth
    about
    notes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createTempAuthToken = /* GraphQL */ `mutation CreateTempAuthToken(
  $input: CreateTempAuthTokenInput!
  $condition: ModelTempAuthTokenConditionInput
) {
  createTempAuthToken(input: $input, condition: $condition) {
    id
    username
    expirationAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTempAuthTokenMutationVariables,
  APITypes.CreateTempAuthTokenMutation
>;
export const updateTempAuthToken = /* GraphQL */ `mutation UpdateTempAuthToken(
  $input: UpdateTempAuthTokenInput!
  $condition: ModelTempAuthTokenConditionInput
) {
  updateTempAuthToken(input: $input, condition: $condition) {
    id
    username
    expirationAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTempAuthTokenMutationVariables,
  APITypes.UpdateTempAuthTokenMutation
>;
export const deleteTempAuthToken = /* GraphQL */ `mutation DeleteTempAuthToken(
  $input: DeleteTempAuthTokenInput!
  $condition: ModelTempAuthTokenConditionInput
) {
  deleteTempAuthToken(input: $input, condition: $condition) {
    id
    username
    expirationAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTempAuthTokenMutationVariables,
  APITypes.DeleteTempAuthTokenMutation
>;
export const createActivationCode = /* GraphQL */ `mutation CreateActivationCode(
  $input: CreateActivationCodeInput!
  $condition: ModelActivationCodeConditionInput
) {
  createActivationCode(input: $input, condition: $condition) {
    id
    durationInDays
    serviceNames
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateActivationCodeMutationVariables,
  APITypes.CreateActivationCodeMutation
>;
export const updateActivationCode = /* GraphQL */ `mutation UpdateActivationCode(
  $input: UpdateActivationCodeInput!
  $condition: ModelActivationCodeConditionInput
) {
  updateActivationCode(input: $input, condition: $condition) {
    id
    durationInDays
    serviceNames
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateActivationCodeMutationVariables,
  APITypes.UpdateActivationCodeMutation
>;
export const deleteActivationCode = /* GraphQL */ `mutation DeleteActivationCode(
  $input: DeleteActivationCodeInput!
  $condition: ModelActivationCodeConditionInput
) {
  deleteActivationCode(input: $input, condition: $condition) {
    id
    durationInDays
    serviceNames
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteActivationCodeMutationVariables,
  APITypes.DeleteActivationCodeMutation
>;
export const createKebbiMaterials = /* GraphQL */ `mutation CreateKebbiMaterials(
  $input: CreateKebbiMaterialsInput!
  $condition: ModelKebbiMaterialsConditionInput
) {
  createKebbiMaterials(input: $input, condition: $condition) {
    materialType
    page
    materials {
      title
      i18n {
        en_US
        __typename
      }
      identityID
      fileName
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateKebbiMaterialsMutationVariables,
  APITypes.CreateKebbiMaterialsMutation
>;
export const updateKebbiMaterials = /* GraphQL */ `mutation UpdateKebbiMaterials(
  $input: UpdateKebbiMaterialsInput!
  $condition: ModelKebbiMaterialsConditionInput
) {
  updateKebbiMaterials(input: $input, condition: $condition) {
    materialType
    page
    materials {
      title
      i18n {
        en_US
        __typename
      }
      identityID
      fileName
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateKebbiMaterialsMutationVariables,
  APITypes.UpdateKebbiMaterialsMutation
>;
export const deleteKebbiMaterials = /* GraphQL */ `mutation DeleteKebbiMaterials(
  $input: DeleteKebbiMaterialsInput!
  $condition: ModelKebbiMaterialsConditionInput
) {
  deleteKebbiMaterials(input: $input, condition: $condition) {
    materialType
    page
    materials {
      title
      i18n {
        en_US
        __typename
      }
      identityID
      fileName
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteKebbiMaterialsMutationVariables,
  APITypes.DeleteKebbiMaterialsMutation
>;
export const createBlocklyProject = /* GraphQL */ `mutation CreateBlocklyProject(
  $input: CreateBlocklyProjectInput!
  $condition: ModelBlocklyProjectConditionInput
) {
  createBlocklyProject(input: $input, condition: $condition) {
    id
    groupID
    workspaceJson
    projectName
    identityID
    files {
      lastUpdatedTimestamp
      name
      category
      identityId
      __typename
    }
    filesV2 {
      s3Key
      versionNumber
      documentType
      __typename
    }
    owner
    createdAt
    updatedAt
    machineLearningProjectIDs
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBlocklyProjectMutationVariables,
  APITypes.CreateBlocklyProjectMutation
>;
export const updateBlocklyProject = /* GraphQL */ `mutation UpdateBlocklyProject(
  $input: UpdateBlocklyProjectInput!
  $condition: ModelBlocklyProjectConditionInput
) {
  updateBlocklyProject(input: $input, condition: $condition) {
    id
    groupID
    workspaceJson
    projectName
    identityID
    files {
      lastUpdatedTimestamp
      name
      category
      identityId
      __typename
    }
    filesV2 {
      s3Key
      versionNumber
      documentType
      __typename
    }
    owner
    createdAt
    updatedAt
    machineLearningProjectIDs
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBlocklyProjectMutationVariables,
  APITypes.UpdateBlocklyProjectMutation
>;
export const deleteBlocklyProject = /* GraphQL */ `mutation DeleteBlocklyProject(
  $input: DeleteBlocklyProjectInput!
  $condition: ModelBlocklyProjectConditionInput
) {
  deleteBlocklyProject(input: $input, condition: $condition) {
    id
    groupID
    workspaceJson
    projectName
    identityID
    files {
      lastUpdatedTimestamp
      name
      category
      identityId
      __typename
    }
    filesV2 {
      s3Key
      versionNumber
      documentType
      __typename
    }
    owner
    createdAt
    updatedAt
    machineLearningProjectIDs
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBlocklyProjectMutationVariables,
  APITypes.DeleteBlocklyProjectMutation
>;
export const createScratchAccount = /* GraphQL */ `mutation CreateScratchAccount(
  $input: CreateScratchAccountInput!
  $condition: ModelScratchAccountConditionInput
) {
  createScratchAccount(input: $input, condition: $condition) {
    lyzaUsername
    orgID
    status
    createAccountFailedDateTimes
    confirmEmailFailedDateTimes
    scratchUsername
    scratchEmail
    scratchPassword
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateScratchAccountMutationVariables,
  APITypes.CreateScratchAccountMutation
>;
export const updateScratchAccount = /* GraphQL */ `mutation UpdateScratchAccount(
  $input: UpdateScratchAccountInput!
  $condition: ModelScratchAccountConditionInput
) {
  updateScratchAccount(input: $input, condition: $condition) {
    lyzaUsername
    orgID
    status
    createAccountFailedDateTimes
    confirmEmailFailedDateTimes
    scratchUsername
    scratchEmail
    scratchPassword
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateScratchAccountMutationVariables,
  APITypes.UpdateScratchAccountMutation
>;
export const deleteScratchAccount = /* GraphQL */ `mutation DeleteScratchAccount(
  $input: DeleteScratchAccountInput!
  $condition: ModelScratchAccountConditionInput
) {
  deleteScratchAccount(input: $input, condition: $condition) {
    lyzaUsername
    orgID
    status
    createAccountFailedDateTimes
    confirmEmailFailedDateTimes
    scratchUsername
    scratchEmail
    scratchPassword
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteScratchAccountMutationVariables,
  APITypes.DeleteScratchAccountMutation
>;
export const createUserSubscriptionEventStatus = /* GraphQL */ `mutation CreateUserSubscriptionEventStatus(
  $input: CreateUserSubscriptionEventStatusInput!
  $condition: ModelUserSubscriptionEventStatusConditionInput
) {
  createUserSubscriptionEventStatus(input: $input, condition: $condition) {
    userID
    machineLearning {
      tfjsToTfliteConversionStatus
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserSubscriptionEventStatusMutationVariables,
  APITypes.CreateUserSubscriptionEventStatusMutation
>;
export const updateUserSubscriptionEventStatus = /* GraphQL */ `mutation UpdateUserSubscriptionEventStatus(
  $input: UpdateUserSubscriptionEventStatusInput!
  $condition: ModelUserSubscriptionEventStatusConditionInput
) {
  updateUserSubscriptionEventStatus(input: $input, condition: $condition) {
    userID
    machineLearning {
      tfjsToTfliteConversionStatus
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserSubscriptionEventStatusMutationVariables,
  APITypes.UpdateUserSubscriptionEventStatusMutation
>;
export const deleteUserSubscriptionEventStatus = /* GraphQL */ `mutation DeleteUserSubscriptionEventStatus(
  $input: DeleteUserSubscriptionEventStatusInput!
  $condition: ModelUserSubscriptionEventStatusConditionInput
) {
  deleteUserSubscriptionEventStatus(input: $input, condition: $condition) {
    userID
    machineLearning {
      tfjsToTfliteConversionStatus
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserSubscriptionEventStatusMutationVariables,
  APITypes.DeleteUserSubscriptionEventStatusMutation
>;
export const createBlocklyProjectCustom = /* GraphQL */ `mutation CreateBlocklyProjectCustom(
  $groupID: String
  $workspaceJson: String!
  $projectName: String!
  $identityID: String!
  $files: [FileTypeInput!]!
) {
  createBlocklyProjectCustom(
    groupID: $groupID
    workspaceJson: $workspaceJson
    projectName: $projectName
    identityID: $identityID
    files: $files
  ) {
    id
    groupID
    workspaceJson
    projectName
    identityID
    files {
      lastUpdatedTimestamp
      name
      category
      identityId
      __typename
    }
    filesV2 {
      s3Key
      versionNumber
      documentType
      __typename
    }
    owner
    createdAt
    updatedAt
    machineLearningProjectIDs
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBlocklyProjectCustomMutationVariables,
  APITypes.CreateBlocklyProjectCustomMutation
>;
export const updateUserOrganizationDetails = /* GraphQL */ `mutation UpdateUserOrganizationDetails(
  $organizationID: String!
  $username: String!
  $role: OrgRole!
) {
  updateUserOrganizationDetails(
    organizationID: $organizationID
    username: $username
    role: $role
  ) {
    user {
      userName
      cognitoIdentityID
      password
      organizations
      profileImage {
        s3Key
        versionNumber
        documentType
        __typename
      }
      parentName
      legalFullName
      preferredName
      country
      email
      phoneNumber
      dateOfBirth
      about
      notes
      createdAt
      updatedAt
      __typename
    }
    organizationUser {
      organizationID
      username
      status
      role
      createdAt
      updatedAt
      isShadowAdmin
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserOrganizationDetailsMutationVariables,
  APITypes.UpdateUserOrganizationDetailsMutation
>;
export const createUserCustom = /* GraphQL */ `mutation CreateUserCustom($input: CreateUserInput!) {
  createUserCustom(input: $input) {
    userName
    cognitoIdentityID
    password
    organizations
    profileImage {
      s3Key
      versionNumber
      documentType
      __typename
    }
    parentName
    legalFullName
    preferredName
    country
    email
    phoneNumber
    dateOfBirth
    about
    notes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserCustomMutationVariables,
  APITypes.CreateUserCustomMutation
>;
export const createUserByOrganizationAdmin = /* GraphQL */ `mutation CreateUserByOrganizationAdmin(
  $userName: ID!
  $legalFullName: String!
  $preferredName: String!
  $country: String!
  $email: String!
  $parentName: String
  $phoneNumber: String
  $dateOfBirth: AWSDateTime
  $about: String
  $orgRole: OrgRole!
  $organizationID: String!
) {
  createUserByOrganizationAdmin(
    userName: $userName
    legalFullName: $legalFullName
    preferredName: $preferredName
    country: $country
    email: $email
    parentName: $parentName
    phoneNumber: $phoneNumber
    dateOfBirth: $dateOfBirth
    about: $about
    orgRole: $orgRole
    organizationID: $organizationID
  ) {
    isUsernameAlreadyExistError
    user {
      user {
        userName
        cognitoIdentityID
        password
        organizations
        profileImage {
          s3Key
          versionNumber
          documentType
          __typename
        }
        parentName
        legalFullName
        preferredName
        country
        email
        phoneNumber
        dateOfBirth
        about
        notes
        createdAt
        updatedAt
        __typename
      }
      organizations {
        userOrg {
          organizationID
          username
          status
          role
          createdAt
          updatedAt
          isShadowAdmin
          __typename
        }
        orgName
        userOrgPermission {
          canReadUsers
          canCreateUser
          canListCurriculums
          canCreateCurriculum
          canListClasses
          canCreateClass
          canListCentres
          canCreateCentres
          canListClassrooms
          canCreateClassrooms
          canHaveScratchAccount
          isKebbiForBusinessEnabled
          isCodelabProEnabled
          __typename
        }
        allowedOrgRoles
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserByOrganizationAdminMutationVariables,
  APITypes.CreateUserByOrganizationAdminMutation
>;
export const createStudentByFromInvitedRegistration = /* GraphQL */ `mutation CreateStudentByFromInvitedRegistration(
  $legalFullName: String!
  $preferredName: String!
  $email: String!
  $parentName: String!
  $phoneNumber: String!
  $dateOfBirth: AWSDateTime!
  $notes: String
) {
  createStudentByFromInvitedRegistration(
    legalFullName: $legalFullName
    preferredName: $preferredName
    email: $email
    parentName: $parentName
    phoneNumber: $phoneNumber
    dateOfBirth: $dateOfBirth
    notes: $notes
  )
}
` as GeneratedMutation<
  APITypes.CreateStudentByFromInvitedRegistrationMutationVariables,
  APITypes.CreateStudentByFromInvitedRegistrationMutation
>;
export const updateUserInfo = /* GraphQL */ `mutation UpdateUserInfo(
  $userName: String!
  $legalFullName: String!
  $preferredName: String!
  $country: String!
  $email: String!
  $parentName: String
  $phoneNumber: String
  $dateOfBirth: AWSDateTime
  $about: String
) {
  updateUserInfo(
    userName: $userName
    legalFullName: $legalFullName
    preferredName: $preferredName
    country: $country
    email: $email
    parentName: $parentName
    phoneNumber: $phoneNumber
    dateOfBirth: $dateOfBirth
    about: $about
  ) {
    userName
    cognitoIdentityID
    password
    organizations
    profileImage {
      s3Key
      versionNumber
      documentType
      __typename
    }
    parentName
    legalFullName
    preferredName
    country
    email
    phoneNumber
    dateOfBirth
    about
    notes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserInfoMutationVariables,
  APITypes.UpdateUserInfoMutation
>;
export const recordUserPwd = /* GraphQL */ `mutation RecordUserPwd($userName: String!, $pwd: String!) {
  recordUserPwd(userName: $userName, pwd: $pwd) {
    userName
    cognitoIdentityID
    password
    organizations
    profileImage {
      s3Key
      versionNumber
      documentType
      __typename
    }
    parentName
    legalFullName
    preferredName
    country
    email
    phoneNumber
    dateOfBirth
    about
    notes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RecordUserPwdMutationVariables,
  APITypes.RecordUserPwdMutation
>;
export const updateUserPassword = /* GraphQL */ `mutation UpdateUserPassword(
  $userName: String!
  $oldPassword: String!
  $newPassword: String!
) {
  updateUserPassword(
    userName: $userName
    oldPassword: $oldPassword
    newPassword: $newPassword
  ) {
    userName
    cognitoIdentityID
    password
    organizations
    profileImage {
      s3Key
      versionNumber
      documentType
      __typename
    }
    parentName
    legalFullName
    preferredName
    country
    email
    phoneNumber
    dateOfBirth
    about
    notes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserPasswordMutationVariables,
  APITypes.UpdateUserPasswordMutation
>;
export const updateUserProfileImage = /* GraphQL */ `mutation UpdateUserProfileImage(
  $userName: String!
  $profileImage: S3FileInput!
) {
  updateUserProfileImage(userName: $userName, profileImage: $profileImage) {
    userName
    cognitoIdentityID
    password
    organizations
    profileImage {
      s3Key
      versionNumber
      documentType
      __typename
    }
    parentName
    legalFullName
    preferredName
    country
    email
    phoneNumber
    dateOfBirth
    about
    notes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserProfileImageMutationVariables,
  APITypes.UpdateUserProfileImageMutation
>;
export const updateUserCognitoIdentityId = /* GraphQL */ `mutation UpdateUserCognitoIdentityId(
  $username: String!
  $cognitoIdentityId: String!
) {
  updateUserCognitoIdentityId(
    username: $username
    cognitoIdentityId: $cognitoIdentityId
  ) {
    userName
    cognitoIdentityID
    password
    organizations
    profileImage {
      s3Key
      versionNumber
      documentType
      __typename
    }
    parentName
    legalFullName
    preferredName
    country
    email
    phoneNumber
    dateOfBirth
    about
    notes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserCognitoIdentityIdMutationVariables,
  APITypes.UpdateUserCognitoIdentityIdMutation
>;
export const createRobotFAQs = /* GraphQL */ `mutation CreateRobotFAQs($inputs: CreateRobotFAQInputs!) {
  createRobotFAQs(inputs: $inputs) {
    organizationID
    question
    questionEmbedding
    embeddingModel
    textToSayEn
    textToSayZh
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRobotFAQsMutationVariables,
  APITypes.CreateRobotFAQsMutation
>;
export const convertTfjsToTfLite = /* GraphQL */ `mutation ConvertTfjsToTfLite($mlProjectID: ID!) {
  convertTfjsToTfLite(mlProjectID: $mlProjectID)
}
` as GeneratedMutation<
  APITypes.ConvertTfjsToTfLiteMutationVariables,
  APITypes.ConvertTfjsToTfLiteMutation
>;
export const createMachineLearningProject = /* GraphQL */ `mutation CreateMachineLearningProject(
  $ownerID: ID!
  $ownerType: OwnerType!
  $projectName: String!
  $modelType: MachineLearningModelType!
) {
  createMachineLearningProject(
    ownerID: $ownerID
    ownerType: $ownerType
    projectName: $projectName
    modelType: $modelType
  ) {
    id
    ownerID
    ownerType
    projectName
    modelType
    modelS3RootDirectory
    classes {
      id
      name
      samplesZipS3File {
        s3Key
        versionNumber
        documentType
        __typename
      }
      isDisabled
      __typename
    }
    targetLatestModelVersion
    tfjsModelVersion
    tfliteModelVersion
    createdAt
    updatedAt
    blocklyProjectIDs
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMachineLearningProjectMutationVariables,
  APITypes.CreateMachineLearningProjectMutation
>;
export const updateMachineLearningProject = /* GraphQL */ `mutation UpdateMachineLearningProject(
  $id: ID!
  $updatedProjectName: String!
  $updatedClasses: [MachineLearningClassUpdate!]!
  $shouldGenerateFileUploadURL: Boolean!
) {
  updateMachineLearningProject(
    id: $id
    updatedProjectName: $updatedProjectName
    updatedClasses: $updatedClasses
    shouldGenerateFileUploadURL: $shouldGenerateFileUploadURL
  ) {
    updatedProject {
      id
      ownerID
      ownerType
      projectName
      modelType
      modelS3RootDirectory
      classes {
        id
        name
        samplesZipS3File {
          s3Key
          versionNumber
          documentType
          __typename
        }
        isDisabled
        __typename
      }
      targetLatestModelVersion
      tfjsModelVersion
      tfliteModelVersion
      createdAt
      updatedAt
      blocklyProjectIDs
      __typename
    }
    getUploadURLsForMLTrainingOutput {
      modelJsonUrl
      modelWeightsUrl
      modelLabelUrl
      classSamplesUrls
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMachineLearningProjectMutationVariables,
  APITypes.UpdateMachineLearningProjectMutation
>;
export const startTfjsModelTraining = /* GraphQL */ `mutation StartTfjsModelTraining($mlProjectID: ID!) {
  startTfjsModelTraining(mlProjectID: $mlProjectID) {
    id
    ownerID
    ownerType
    projectName
    modelType
    modelS3RootDirectory
    classes {
      id
      name
      samplesZipS3File {
        s3Key
        versionNumber
        documentType
        __typename
      }
      isDisabled
      __typename
    }
    targetLatestModelVersion
    tfjsModelVersion
    tfliteModelVersion
    createdAt
    updatedAt
    blocklyProjectIDs
    __typename
  }
}
` as GeneratedMutation<
  APITypes.StartTfjsModelTrainingMutationVariables,
  APITypes.StartTfjsModelTrainingMutation
>;
export const deleteMachineLearningProject = /* GraphQL */ `mutation DeleteMachineLearningProject($id: ID!) {
  deleteMachineLearningProject(id: $id)
}
` as GeneratedMutation<
  APITypes.DeleteMachineLearningProjectMutationVariables,
  APITypes.DeleteMachineLearningProjectMutation
>;
export const createCodespaceProject = /* GraphQL */ `mutation CreateCodespaceProject(
  $ownerID: ID!
  $ownerType: OwnerType!
  $projectName: String!
  $language: Languages!
) {
  createCodespaceProject(
    ownerID: $ownerID
    ownerType: $ownerType
    projectName: $projectName
    language: $language
  ) {
    id
    ownerID
    ownerType
    projectName
    language
    code
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCodespaceProjectMutationVariables,
  APITypes.CreateCodespaceProjectMutation
>;
export const updateCodespaceProject = /* GraphQL */ `mutation UpdateCodespaceProject(
  $id: ID!
  $updatedProjectName: String!
  $code: String!
) {
  updateCodespaceProject(
    id: $id
    updatedProjectName: $updatedProjectName
    code: $code
  ) {
    updatedProject {
      id
      ownerID
      ownerType
      projectName
      language
      code
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCodespaceProjectMutationVariables,
  APITypes.UpdateCodespaceProjectMutation
>;
export const deleteCodespaceProject = /* GraphQL */ `mutation DeleteCodespaceProject($id: ID!) {
  deleteCodespaceProject(id: $id)
}
` as GeneratedMutation<
  APITypes.DeleteCodespaceProjectMutationVariables,
  APITypes.DeleteCodespaceProjectMutation
>;
export const createCentre = /* GraphQL */ `mutation CreateCentre(
  $organizationID: ID!
  $data: CentreDataInput!
  $status: ItemStatus!
) {
  createCentre(organizationID: $organizationID, data: $data, status: $status) {
    id
    organizationID
    data {
      name
      address
      postcode
      country
      __typename
    }
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCentreMutationVariables,
  APITypes.CreateCentreMutation
>;
export const updateCentre = /* GraphQL */ `mutation UpdateCentre($id: ID!, $data: CentreDataInput!, $status: ItemStatus!) {
  updateCentre(id: $id, data: $data, status: $status) {
    id
    organizationID
    data {
      name
      address
      postcode
      country
      __typename
    }
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCentreMutationVariables,
  APITypes.UpdateCentreMutation
>;
export const createClassroom = /* GraphQL */ `mutation CreateClassroom(
  $organizationID: ID!
  $data: ClassroomDataInput!
  $status: ItemStatus!
) {
  createClassroom(
    organizationID: $organizationID
    data: $data
    status: $status
  ) {
    id
    organizationID
    data {
      centreId
      centre {
        name
        address
        postcode
        country
        __typename
      }
      name
      __typename
    }
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateClassroomMutationVariables,
  APITypes.CreateClassroomMutation
>;
export const updateClassroom = /* GraphQL */ `mutation UpdateClassroom(
  $id: ID!
  $data: ClassroomDataInput!
  $status: ItemStatus!
) {
  updateClassroom(id: $id, data: $data, status: $status) {
    id
    organizationID
    data {
      centreId
      centre {
        name
        address
        postcode
        country
        __typename
      }
      name
      __typename
    }
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClassroomMutationVariables,
  APITypes.UpdateClassroomMutation
>;
export const createCurriculum = /* GraphQL */ `mutation CreateCurriculum(
  $organizationID: ID!
  $data: CurriculumDataInput!
  $status: ItemStatus!
) {
  createCurriculum(
    organizationID: $organizationID
    data: $data
    status: $status
  ) {
    id
    organizationID
    data {
      name
      description
      covers {
        s3Key
        versionNumber
        documentType
        __typename
      }
      type
      sessionTemplates {
        sessionName
        sessionDescription
        sessionDurationInMinutes
        evaluationItems {
          metric
          formats {
            format
            required
            __typename
          }
          __typename
        }
        requireSessionPhotoOrVideoFromTeacher
        requireSessionSummaryFromTeacher
        requireEachStudentPhotoOrVideoFromTeacher
        requiredCommunications
        __typename
      }
      endOfClassEvaluationsTemplate {
        metric
        formats {
          format
          required
          __typename
        }
        __typename
      }
      requiredClassLevelCommunications
      __typename
    }
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCurriculumMutationVariables,
  APITypes.CreateCurriculumMutation
>;
export const updateCurriculumStatus = /* GraphQL */ `mutation UpdateCurriculumStatus($id: ID!, $status: ItemStatus!) {
  updateCurriculumStatus(id: $id, status: $status) {
    id
    organizationID
    data {
      name
      description
      covers {
        s3Key
        versionNumber
        documentType
        __typename
      }
      type
      sessionTemplates {
        sessionName
        sessionDescription
        sessionDurationInMinutes
        evaluationItems {
          metric
          formats {
            format
            required
            __typename
          }
          __typename
        }
        requireSessionPhotoOrVideoFromTeacher
        requireSessionSummaryFromTeacher
        requireEachStudentPhotoOrVideoFromTeacher
        requiredCommunications
        __typename
      }
      endOfClassEvaluationsTemplate {
        metric
        formats {
          format
          required
          __typename
        }
        __typename
      }
      requiredClassLevelCommunications
      __typename
    }
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCurriculumStatusMutationVariables,
  APITypes.UpdateCurriculumStatusMutation
>;
export const updateCurriculumData = /* GraphQL */ `mutation UpdateCurriculumData(
  $id: ID!
  $affectedClassIds: [ID!]!
  $curriculumData: CurriculumDataInput!
  $currentVersionUpdatedAt: AWSDateTime!
) {
  updateCurriculumData(
    id: $id
    affectedClassIds: $affectedClassIds
    curriculumData: $curriculumData
    currentVersionUpdatedAt: $currentVersionUpdatedAt
  ) {
    actionableErrorMsg
    updatedCurriculum {
      id
      organizationID
      data {
        name
        description
        covers {
          s3Key
          versionNumber
          documentType
          __typename
        }
        type
        sessionTemplates {
          sessionName
          sessionDescription
          sessionDurationInMinutes
          evaluationItems {
            metric
            formats {
              format
              required
              __typename
            }
            __typename
          }
          requireSessionPhotoOrVideoFromTeacher
          requireSessionSummaryFromTeacher
          requireEachStudentPhotoOrVideoFromTeacher
          requiredCommunications
          __typename
        }
        endOfClassEvaluationsTemplate {
          metric
          formats {
            format
            required
            __typename
          }
          __typename
        }
        requiredClassLevelCommunications
        __typename
      }
      status
      createdAt
      updatedAt
      __typename
    }
    updatedClasses {
      updatedClass {
        id
        organizationID
        curriculumId
        curriculum {
          name
          description
          covers {
            s3Key
            versionNumber
            documentType
            __typename
          }
          type
          sessionTemplates {
            sessionName
            sessionDescription
            sessionDurationInMinutes
            evaluationItems {
              metric
              formats {
                format
                required
                __typename
              }
              __typename
            }
            requireSessionPhotoOrVideoFromTeacher
            requireSessionSummaryFromTeacher
            requireEachStudentPhotoOrVideoFromTeacher
            requiredCommunications
            __typename
          }
          endOfClassEvaluationsTemplate {
            metric
            formats {
              format
              required
              __typename
            }
            __typename
          }
          requiredClassLevelCommunications
          __typename
        }
        sessions {
          sessionLevelSharedFiles {
            s3Key
            versionNumber
            documentType
            __typename
          }
          sessionSummary
          startDateTime
          classroom {
            centreId
            centre {
              name
              address
              postcode
              country
              __typename
            }
            name
            __typename
          }
          __typename
        }
        classLevelSharedFiles {
          s3Key
          versionNumber
          documentType
          __typename
        }
        classCommunicationOption {
          isSendToParent
          sendToNonParentEmail
          sendToNonParentPhoneNumber
          __typename
        }
        status
        createdAt
        updatedAt
        __typename
      }
      updatedClassStudentRecords {
        id
        organizationID
        username
        classID
        sessionRecords {
          isAssigned
          attended
          notAttendedReason
          documents {
            s3Key
            versionNumber
            documentType
            __typename
          }
          sessionEvaluations {
            metric
            results {
              format
              required
              result
              __typename
            }
            __typename
          }
          sentCommunications
          updatedAt
          updatedByUsername
          __typename
        }
        endOfClassEvaluations {
          metric
          results {
            format
            required
            result
            __typename
          }
          __typename
        }
        sentClassLevelCommunications
        documents {
          s3Key
          versionNumber
          documentType
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCurriculumDataMutationVariables,
  APITypes.UpdateCurriculumDataMutation
>;
export const createClassForCurriculum = /* GraphQL */ `mutation CreateClassForCurriculum($createClassInput: CreateClassInput!) {
  createClassForCurriculum(createClassInput: $createClassInput) {
    id
    organizationID
    curriculumId
    curriculum {
      name
      description
      covers {
        s3Key
        versionNumber
        documentType
        __typename
      }
      type
      sessionTemplates {
        sessionName
        sessionDescription
        sessionDurationInMinutes
        evaluationItems {
          metric
          formats {
            format
            required
            __typename
          }
          __typename
        }
        requireSessionPhotoOrVideoFromTeacher
        requireSessionSummaryFromTeacher
        requireEachStudentPhotoOrVideoFromTeacher
        requiredCommunications
        __typename
      }
      endOfClassEvaluationsTemplate {
        metric
        formats {
          format
          required
          __typename
        }
        __typename
      }
      requiredClassLevelCommunications
      __typename
    }
    sessions {
      sessionLevelSharedFiles {
        s3Key
        versionNumber
        documentType
        __typename
      }
      sessionSummary
      startDateTime
      classroom {
        centreId
        centre {
          name
          address
          postcode
          country
          __typename
        }
        name
        __typename
      }
      __typename
    }
    classLevelSharedFiles {
      s3Key
      versionNumber
      documentType
      __typename
    }
    classCommunicationOption {
      isSendToParent
      sendToNonParentEmail
      sendToNonParentPhoneNumber
      __typename
    }
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateClassForCurriculumMutationVariables,
  APITypes.CreateClassForCurriculumMutation
>;
export const updateClassSessionDocuments = /* GraphQL */ `mutation UpdateClassSessionDocuments(
  $classID: ID!
  $sessionIndex: Int!
  $files: [S3FileInput!]!
  $currentVersionUpdatedAt: AWSDateTime!
) {
  updateClassSessionDocuments(
    classID: $classID
    sessionIndex: $sessionIndex
    files: $files
    currentVersionUpdatedAt: $currentVersionUpdatedAt
  ) {
    actionableErrorMsg
    classObject {
      id
      organizationID
      curriculumId
      curriculum {
        name
        description
        covers {
          s3Key
          versionNumber
          documentType
          __typename
        }
        type
        sessionTemplates {
          sessionName
          sessionDescription
          sessionDurationInMinutes
          evaluationItems {
            metric
            formats {
              format
              required
              __typename
            }
            __typename
          }
          requireSessionPhotoOrVideoFromTeacher
          requireSessionSummaryFromTeacher
          requireEachStudentPhotoOrVideoFromTeacher
          requiredCommunications
          __typename
        }
        endOfClassEvaluationsTemplate {
          metric
          formats {
            format
            required
            __typename
          }
          __typename
        }
        requiredClassLevelCommunications
        __typename
      }
      sessions {
        sessionLevelSharedFiles {
          s3Key
          versionNumber
          documentType
          __typename
        }
        sessionSummary
        startDateTime
        classroom {
          centreId
          centre {
            name
            address
            postcode
            country
            __typename
          }
          name
          __typename
        }
        __typename
      }
      classLevelSharedFiles {
        s3Key
        versionNumber
        documentType
        __typename
      }
      classCommunicationOption {
        isSendToParent
        sendToNonParentEmail
        sendToNonParentPhoneNumber
        __typename
      }
      status
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClassSessionDocumentsMutationVariables,
  APITypes.UpdateClassSessionDocumentsMutation
>;
export const updateClassSessionSummary = /* GraphQL */ `mutation UpdateClassSessionSummary(
  $classID: ID!
  $sessionIndex: Int!
  $sessionSummary: String!
  $currentVersionUpdatedAt: AWSDateTime!
) {
  updateClassSessionSummary(
    classID: $classID
    sessionIndex: $sessionIndex
    sessionSummary: $sessionSummary
    currentVersionUpdatedAt: $currentVersionUpdatedAt
  ) {
    actionableErrorMsg
    classObject {
      id
      organizationID
      curriculumId
      curriculum {
        name
        description
        covers {
          s3Key
          versionNumber
          documentType
          __typename
        }
        type
        sessionTemplates {
          sessionName
          sessionDescription
          sessionDurationInMinutes
          evaluationItems {
            metric
            formats {
              format
              required
              __typename
            }
            __typename
          }
          requireSessionPhotoOrVideoFromTeacher
          requireSessionSummaryFromTeacher
          requireEachStudentPhotoOrVideoFromTeacher
          requiredCommunications
          __typename
        }
        endOfClassEvaluationsTemplate {
          metric
          formats {
            format
            required
            __typename
          }
          __typename
        }
        requiredClassLevelCommunications
        __typename
      }
      sessions {
        sessionLevelSharedFiles {
          s3Key
          versionNumber
          documentType
          __typename
        }
        sessionSummary
        startDateTime
        classroom {
          centreId
          centre {
            name
            address
            postcode
            country
            __typename
          }
          name
          __typename
        }
        __typename
      }
      classLevelSharedFiles {
        s3Key
        versionNumber
        documentType
        __typename
      }
      classCommunicationOption {
        isSendToParent
        sendToNonParentEmail
        sendToNonParentPhoneNumber
        __typename
      }
      status
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClassSessionSummaryMutationVariables,
  APITypes.UpdateClassSessionSummaryMutation
>;
export const updateClassStatus = /* GraphQL */ `mutation UpdateClassStatus($classID: ID!, $status: ItemStatus!) {
  updateClassStatus(classID: $classID, status: $status) {
    id
    organizationID
    curriculumId
    curriculum {
      name
      description
      covers {
        s3Key
        versionNumber
        documentType
        __typename
      }
      type
      sessionTemplates {
        sessionName
        sessionDescription
        sessionDurationInMinutes
        evaluationItems {
          metric
          formats {
            format
            required
            __typename
          }
          __typename
        }
        requireSessionPhotoOrVideoFromTeacher
        requireSessionSummaryFromTeacher
        requireEachStudentPhotoOrVideoFromTeacher
        requiredCommunications
        __typename
      }
      endOfClassEvaluationsTemplate {
        metric
        formats {
          format
          required
          __typename
        }
        __typename
      }
      requiredClassLevelCommunications
      __typename
    }
    sessions {
      sessionLevelSharedFiles {
        s3Key
        versionNumber
        documentType
        __typename
      }
      sessionSummary
      startDateTime
      classroom {
        centreId
        centre {
          name
          address
          postcode
          country
          __typename
        }
        name
        __typename
      }
      __typename
    }
    classLevelSharedFiles {
      s3Key
      versionNumber
      documentType
      __typename
    }
    classCommunicationOption {
      isSendToParent
      sendToNonParentEmail
      sendToNonParentPhoneNumber
      __typename
    }
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClassStatusMutationVariables,
  APITypes.UpdateClassStatusMutation
>;
export const createClassStudentRecords = /* GraphQL */ `mutation CreateClassStudentRecords(
  $createClassStudentRecordInputs: [CreateClassStudentRecordInput!]!
) {
  createClassStudentRecords(
    createClassStudentRecordInputs: $createClassStudentRecordInputs
  ) {
    id
    organizationID
    username
    classID
    sessionRecords {
      isAssigned
      attended
      notAttendedReason
      documents {
        s3Key
        versionNumber
        documentType
        __typename
      }
      sessionEvaluations {
        metric
        results {
          format
          required
          result
          __typename
        }
        __typename
      }
      sentCommunications
      updatedAt
      updatedByUsername
      __typename
    }
    endOfClassEvaluations {
      metric
      results {
        format
        required
        result
        __typename
      }
      __typename
    }
    sentClassLevelCommunications
    documents {
      s3Key
      versionNumber
      documentType
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateClassStudentRecordsMutationVariables,
  APITypes.CreateClassStudentRecordsMutation
>;
export const updateClassStudentRecords = /* GraphQL */ `mutation UpdateClassStudentRecords(
  $updateClassStudentRecordInputs: [UpdateClassStudentRecordInput!]!
) {
  updateClassStudentRecords(
    updateClassStudentRecordInputs: $updateClassStudentRecordInputs
  ) {
    errorReasonForUser
    results {
      id
      organizationID
      username
      classID
      sessionRecords {
        isAssigned
        attended
        notAttendedReason
        documents {
          s3Key
          versionNumber
          documentType
          __typename
        }
        sessionEvaluations {
          metric
          results {
            format
            required
            result
            __typename
          }
          __typename
        }
        sentCommunications
        updatedAt
        updatedByUsername
        __typename
      }
      endOfClassEvaluations {
        metric
        results {
          format
          required
          result
          __typename
        }
        __typename
      }
      sentClassLevelCommunications
      documents {
        s3Key
        versionNumber
        documentType
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClassStudentRecordsMutationVariables,
  APITypes.UpdateClassStudentRecordsMutation
>;
export const removeClassStudentRecords = /* GraphQL */ `mutation RemoveClassStudentRecords(
  $removeClassStudentRecordInputs: [RemoveClassStudentRecordInput!]!
) {
  removeClassStudentRecords(
    removeClassStudentRecordInputs: $removeClassStudentRecordInputs
  )
}
` as GeneratedMutation<
  APITypes.RemoveClassStudentRecordsMutationVariables,
  APITypes.RemoveClassStudentRecordsMutation
>;
export const createClassTeacherRecords = /* GraphQL */ `mutation CreateClassTeacherRecords(
  $createClassTeacherRecordInputs: [CreateClassTeacherRecordInput!]!
) {
  createClassTeacherRecords(
    createClassTeacherRecordInputs: $createClassTeacherRecordInputs
  ) {
    id
    organizationID
    username
    classID
    sessionRecords {
      role
      documents {
        s3Key
        versionNumber
        documentType
        __typename
      }
      updatedAt
      updatedByUsername
      __typename
    }
    documents {
      s3Key
      versionNumber
      documentType
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateClassTeacherRecordsMutationVariables,
  APITypes.CreateClassTeacherRecordsMutation
>;
export const updateClassTeacherRecords = /* GraphQL */ `mutation UpdateClassTeacherRecords(
  $updateClassTeacherRecordInputs: [UpdateClassTeacherRecordInput!]!
) {
  updateClassTeacherRecords(
    updateClassTeacherRecordInputs: $updateClassTeacherRecordInputs
  ) {
    errorReasonForUser
    results {
      id
      organizationID
      username
      classID
      sessionRecords {
        role
        documents {
          s3Key
          versionNumber
          documentType
          __typename
        }
        updatedAt
        updatedByUsername
        __typename
      }
      documents {
        s3Key
        versionNumber
        documentType
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClassTeacherRecordsMutationVariables,
  APITypes.UpdateClassTeacherRecordsMutation
>;
export const removeClassTeacherRecords = /* GraphQL */ `mutation RemoveClassTeacherRecords(
  $removeClassTeacherRecordInputs: [RemoveClassTeacherRecordInput!]!
) {
  removeClassTeacherRecords(
    removeClassTeacherRecordInputs: $removeClassTeacherRecordInputs
  )
}
` as GeneratedMutation<
  APITypes.RemoveClassTeacherRecordsMutationVariables,
  APITypes.RemoveClassTeacherRecordsMutation
>;
export const sendWhatsAppTextMessage = /* GraphQL */ `mutation SendWhatsAppTextMessage($body: String!, $toPhoneNumber: String!) {
  sendWhatsAppTextMessage(body: $body, toPhoneNumber: $toPhoneNumber)
}
` as GeneratedMutation<
  APITypes.SendWhatsAppTextMessageMutationVariables,
  APITypes.SendWhatsAppTextMessageMutation
>;
export const sendWhatsAppCommunication = /* GraphQL */ `mutation SendWhatsAppCommunication(
  $classStudentRecordId: String!
  $classID: String!
  $sessionIndex: Int
  $communicationType: StudentCommunicationType!
) {
  sendWhatsAppCommunication(
    classStudentRecordId: $classStudentRecordId
    classID: $classID
    sessionIndex: $sessionIndex
    communicationType: $communicationType
  )
}
` as GeneratedMutation<
  APITypes.SendWhatsAppCommunicationMutationVariables,
  APITypes.SendWhatsAppCommunicationMutation
>;
export const manuallyProvideScratchAccount = /* GraphQL */ `mutation ManuallyProvideScratchAccount(
  $lyzaUsername: ID!
  $orgID: ID!
  $scratchUsername: ID!
  $scratchPassword: String!
  $scratchEmail: String!
) {
  manuallyProvideScratchAccount(
    lyzaUsername: $lyzaUsername
    orgID: $orgID
    scratchUsername: $scratchUsername
    scratchPassword: $scratchPassword
    scratchEmail: $scratchEmail
  ) {
    lyzaUsername
    orgID
    status
    createAccountFailedDateTimes
    confirmEmailFailedDateTimes
    scratchUsername
    scratchEmail
    scratchPassword
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ManuallyProvideScratchAccountMutationVariables,
  APITypes.ManuallyProvideScratchAccountMutation
>;
export const triggerScratchAccountCreationOrConfirmationFlow = /* GraphQL */ `mutation TriggerScratchAccountCreationOrConfirmationFlow(
  $lyzaUsername: ID!
  $orgID: ID!
) {
  triggerScratchAccountCreationOrConfirmationFlow(
    lyzaUsername: $lyzaUsername
    orgID: $orgID
  )
}
` as GeneratedMutation<
  APITypes.TriggerScratchAccountCreationOrConfirmationFlowMutationVariables,
  APITypes.TriggerScratchAccountCreationOrConfirmationFlowMutation
>;
